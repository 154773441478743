import {
  SINGLE_CHOICE,
  MULTIPLE_CHOICE,
  SINGLE_MATRIX,
  SHORT_TEXT,
  LONG_TEXT,
  DATE,
  FINANCIAL,
  NUMERICAL,
  RATING,
  OPINION_SCALE,
  COUNTRY,
} from 'src/data/questionTypes';
import COUNTRIES from 'src/data/countries.json';
import { ANSWERED, EMPTY, HAS_VALUE, NO_ANSWER, OTHER } from '../constants';
import { capitalize } from 'src/utils/string';
import uniq from 'ramda/src/uniq';
import flatten from 'ramda/src/flatten';
import { formatMoney, formatNumber } from 'accounting';

const isNumeric = (v) =>
  v !== '' && v != null && !isNaN(v) && isFinite(Number(v));
const find = (indexes, path) =>
  path.split('.').reduce((o, key) => (o && o[key] ? o[key] : null), indexes);
const trimValue = (input) =>
  input && input.length > 0 && typeof input === 'string' ? input.trim() : input;
const hasValue = (input) =>
  !(input == undefined || input === '' || input === ' ');
const castDate = (value) => new Date(value).toDateString();
const nullifyDummy = (name) => (/\s\(internal\)$/.test(name) ? null : name);
const innerData = (data) => data?.slice(1, -3) || [];
const innerHead = (data) => data?.[0]?.slice(1, -3) || [];

const valueTypeForQuestionType = (question) => {
  if (
    [NUMERICAL].includes(question.type) ||
    [NUMERICAL].includes(question.matrixQuestionType)
  )
    return 'number';
  if (
    [FINANCIAL].includes(question.type) ||
    [FINANCIAL].includes(question.matrixQuestionType)
  )
    return 'currency';
  if (
    [MULTIPLE_CHOICE, COUNTRY].includes(question.type) ||
    [MULTIPLE_CHOICE, COUNTRY].includes(question.matrixQuestionType)
  )
    return 'count';
  return 'text';
};
const isNumericalQuestion = (question) =>
  [NUMERICAL, FINANCIAL, RATING, OPINION_SCALE].includes(question.type) ||
  [NUMERICAL, FINANCIAL, RATING, OPINION_SCALE].includes(
    question.matrixQuestionType
  );

export const respondentLabelFromResponse = (
  gs,
  isMultiSeries = false
): string => {
  const hasIdentifier = Boolean(gs.respondent_name || gs.respondent_email);
  const hasGrantGrantee = Boolean(
    nullifyDummy(gs.grant_name) || nullifyDummy(gs.grantee_name)
  );
  return [
    isMultiSeries ? gs.batch : undefined,
    hasIdentifier ? gs.respondent_name : undefined,
    hasIdentifier ? gs.respondent_email : undefined,
    !hasIdentifier ? nullifyDummy(gs.grant_name) : undefined,
    !hasIdentifier ? nullifyDummy(gs.grantee_name) : undefined,
    !hasIdentifier && !hasGrantGrantee ? `Response ${gs.id}` : undefined,
  ]
    .filter((el) => el != undefined)
    .join('; ');
};

export const getOptionsForQuestion = (
  question,
  answers,
  granteeSurveys
): string[] => {
  // Will be displayed into chart
  const possibleChoices = uniq(question.options?.map((o) => o.title) ?? []);
  const possibleOther = answers.some((a) => hasValue(a.response.other_value))
    ? [OTHER]
    : [];
  const validGs = answers.filter(
    (a) =>
      granteeSurveys
        ?.map((gs) => Number(gs.id))
        .includes(Number(a.grantee_survey?.id))
  );

  switch (question.type) {
    case SINGLE_MATRIX:
      return uniq(question.matrixValues.map((r) => r.title) || []);

    case SINGLE_CHOICE:
    case MULTIPLE_CHOICE:
      return uniq([...possibleChoices, ...possibleOther, NO_ANSWER]);

    case SHORT_TEXT:
    case LONG_TEXT:
    case NUMERICAL:
    case FINANCIAL:
    case RATING:
      return uniq(
        validGs.map((a) => trimValue(a.response.simple_value) ?? NO_ANSWER)
      );

    case OPINION_SCALE:
      const minValue = question.settings?.minValue || 0;
      const maxValue = question.settings?.maxValue || 4;
      const range = Array.from(Array(1 + (maxValue - minValue)).keys());
      const scale = range.map((r) => r + minValue);
      return [...scale.map((s) => s), NO_ANSWER];
    case DATE:
      return uniq(
        validGs.map((a) =>
          a.response.simple_value
            ? castDate(a.response.simple_value)
            : NO_ANSWER
        )
      );
    case COUNTRY:
      const selectedCountries = uniq(
        flatten(validGs.map((responses) => responses.response.object_value))
      );
      const countryNames = COUNTRIES.map((country) => country.name);
      return selectedCountries.filter((selectedCountry) =>
        countryNames.includes(selectedCountry)
      );
    default:
      return [EMPTY, HAS_VALUE];
  }
};

const sum = (array) => array.reduce((pv, cv) => pv + cv, 0);

const getValuesForQuestion = (
  question,
  answer: any,
  answers = [],
  granteeSurveys
): { value: any; label: string; subLabel: string | undefined }[] => {
  const { simple_value, other_value, object_value } = answer?.response ?? {};
  const options = getOptionsForQuestion(
    question,
    answers,
    granteeSurveys
  ).filter(hasValue);
  const setValue = (option, value) => ({
    value,
    label: question.question,
    subLabel: option,
  });
  const hasAnsweredOther = other_value != undefined && other_value !== '';

  const hasAnsweredSimple = simple_value != undefined;
  const hasSimpleValueMatch = (option) =>
    option === simple_value || option === trimValue(simple_value || '');
  const hasDateValueMatch = (option) =>
    option === castDate(simple_value) ||
    option === trimValue(castDate(simple_value) || '');

  if (question.type === SINGLE_MATRIX)
    // TODO SEB-188: RENDERING MULTIPLE_CHOICE SINGLE MATRIX
    return options.map((option) => {
      const matches = Object.entries(object_value || {})
        .filter(([key, _v]) => key.startsWith(option))
        .map(([_k, value]) => value);

      if (matches.length > 0 && question.matrixQuestionType === FINANCIAL)
        return setValue(option, sum(matches.map(Number).filter(isNumeric)));
      if (matches.length > 0 && question.matrixQuestionType === NUMERICAL)
        return setValue(option, sum(matches.map(Number).filter(isNumeric)));
      const allNumeric = Object.entries(object_value || {})
        .filter(([key, _v]) => key.startsWith(option))
        .map(([_k, value]) => value)
        .every(isNumeric);
      if (
        matches.length > 0 &&
        allNumeric &&
        (question.matrixQuestionType === SHORT_TEXT ||
          question.matrixQuestionType === SINGLE_CHOICE)
      )
        return setValue(option, sum(matches.map(Number).filter(isNumeric)));

      if (question.matrixQuestionType === SINGLE_CHOICE) {
        return setValue(
          option,
          question.settings.columns
            .map((col) => col.title)
            .map((col) => (col === object_value?.[option] ? 1 : 0))
        );
      }

      if (question.matrixQuestionType === SHORT_TEXT)
        return setValue(option, object_value?.[option]?.length > 0 ? 1 : 0);
      return setValue(option, object_value?.[option] || 0); //should be zero, otherwise wrong calc
    });

  if (question.type === MULTIPLE_CHOICE)
    return options.map((option) => {
      if (option === NO_ANSWER)
        return setValue(
          option,
          !object_value?.length && !hasAnsweredOther ? 1 : 0
        ); // means count noAnswer
      if (option === OTHER) return setValue(option, hasAnsweredOther ? 1 : 0); // means count Other
      return setValue(
        option,
        Number(
          Boolean(Array.isArray(object_value) && object_value.includes(option))
        )
      );
    });
  if (question.type === SINGLE_CHOICE)
    return options.map((option) => {
      if (
        option === NO_ANSWER &&
        !hasAnsweredOther &&
        !`${simple_value || ''}`.trim()
      )
        return setValue(option, 1); // means count noAnswer
      if (option === OTHER) return setValue(option, hasAnsweredOther ? 1 : 0); // means count Other
      return setValue(option, hasSimpleValueMatch(option) ? 1 : 0);
    });
  if (question.type === SHORT_TEXT)
    return options.map((option) => {
      if (option === NO_ANSWER && simple_value == undefined)
        return setValue(option, 1); // means count noAnswer
      return setValue(option, hasSimpleValueMatch(option) ? 1 : 0);
    });
  if (question.type === LONG_TEXT)
    return options.map((option) => {
      if (option === NO_ANSWER && simple_value == undefined)
        return setValue(option, 0);
      return setValue(option, hasSimpleValueMatch(option) ? 1 : 0);
    });
  if (question.type === RATING)
    return options.map((option) => {
      if (option === NO_ANSWER && simple_value == undefined)
        return setValue(option, 0);
      return setValue(option, hasSimpleValueMatch(option) ? 1 : 0);
    });
  if (question.type === OPINION_SCALE)
    return options.map((option) => {
      if (option === NO_ANSWER && simple_value == undefined)
        return setValue(option, 0);
      return setValue(option, hasSimpleValueMatch(option) ? 1 : 0);
    });
  if (question.type === DATE)
    return options.map((option) => {
      if (option === NO_ANSWER && simple_value == undefined)
        return setValue(option, 0);
      return setValue(option, hasDateValueMatch(option) ? 1 : 0);
    });

  if (question.type === FINANCIAL) {
    return [
      {
        value: hasAnsweredSimple ? simple_value : 0,
        label: question.question,
        subLabel: undefined,
      },
    ];
  }
  if (question.type === NUMERICAL) {
    return [
      {
        value: hasAnsweredSimple ? simple_value : 0,
        label: question.question,
        subLabel: undefined,
      },
    ];
  }

  if (question.type === COUNTRY) {
    return options.map((option) => {
      if (option === NO_ANSWER)
        return setValue(
          option,
          !object_value?.length && !hasAnsweredOther ? 1 : 0
        ); // means count noAnswer
      if (option === OTHER) return setValue(option, hasAnsweredOther ? 1 : 0); // means count Other
      return setValue(
        option,
        Number(
          Boolean(Array.isArray(object_value) && object_value.includes(option))
        )
      );
    });
  }

  return [
    {
      value: hasAnsweredSimple ? simple_value : 0,
      label: question.question,
      subLabel: undefined,
    },
  ];
};

const mergeMultiSeries = (datapoint, datapointSeries) => {
  if (datapointSeries.length < 2) return datapoint.values;
  const values = [].concat(
    datapoint.values,
    ...datapointSeries
      .map((series) =>
        series.data.find((d) => {
          return d.grantee_survey == datapoint.source.grantee_survey;
        })
      )
      .filter(
        (dp) =>
          find(datapoint, 'source.grantee_survey') != find(dp, 'source.answer')
      )
      .map((dp) => dp.values)
  );
  return values;
};

// will transpose in format nx2 [["Responses", "Count (n=36)"],["Yes", 28],["No", 8],["No Answer", 0]]
export const aggregateRows = (question, rows) => {
  const payload: Array<[string, string | number]> = [];
  const [headers, ...bodies] = rows;

  headers.forEach((headerCell, index) =>
    index == 0
      ? payload.push([
          headerCell,
          isNumericalQuestion(question)
            ? 'Total'
            : `Count (n=${rows.length - 1})`,
        ])
      : payload.push([headerCell, 0])
  );

  bodies.forEach((row) => {
    (row || []).slice(1).forEach((cell, cellIndex) => {
      payload[cellIndex + 1] ??= [row[0], 0];
      payload[cellIndex + 1]![1] += isNumeric(cell) ? cell : 1;
    });
  });

  return payload;
};

// will group in format dataPointsToTabularData
const tabularDataForAttribute = (attribute, surveys): Array<any[]> => {
  const NOT_IDENTIFIED = 'Not Identified';
  const unique = surveys
    .map((gs) => nullifyDummy(gs[attribute]) || NOT_IDENTIFIED)
    .filter((el, index, arr) => arr.indexOf(el) === index);

  return [
    [attribute, ...unique, 'Series', 'Grant name', 'Grantee name'],
  ].concat(
    surveys.map((gs) => [
      respondentLabelFromResponse(gs),
      ...unique.map((value) =>
        gs[attribute] == value ||
        (nullifyDummy(gs[attribute]) === null && NOT_IDENTIFIED === value)
          ? 1
          : 0
      ),
      gs.batch,
      gs.grant_name,
      gs.grantee_name,
    ])
  );
};

const singleMatrixChoiceTabularData = (question, datapoint) => {
  const matrixValues = datapoint.map((dp) =>
    dp.values.map((value) => value.value)
  );
  const rows = question.matrixValues;
  const columns = question.settings.columns;

  const header = [
    'Responses',
    ...columns.map((col) => col.title),
    'Series',
    'Grant name',
    'Grantee name',
  ];

  const matrixBody = rows.map((row, rowIndex) => {
    const rowValues = matrixValues.map((matrix) => matrix[rowIndex]);

    const squishedRowValues = rowValues.reduce((total, rowValue) => {
      return total.map((value, idx) => value + (rowValue[idx] || 0));
    }, Array(columns.length).fill(0));

    return [row.title, ...squishedRowValues, 'default', NO_ANSWER, NO_ANSWER];
  });

  return [header].concat(matrixBody);
};

export const dataPointsToTabularData = (
  question,
  granteeSurveys: any = [],
  answers
): (string | number)[][] | undefined => {
  if (!question) return undefined;
  if (!granteeSurveys?.length) return [['Responses', NO_ANSWER]];

  if (typeof question == 'string')
    return tabularDataForAttribute(question, granteeSurveys);

  const isMultiSeries =
    [...new Set(granteeSurveys.map((a) => a.batch))]?.length > 1;
  const getAnswer = (gs) =>
    answers?.find((a) => a?.grantee_survey.id == gs?.id) || {};

  const datapoint = granteeSurveys.map((gs) => ({
    type: valueTypeForQuestionType(question),
    label: respondentLabelFromResponse(gs, isMultiSeries),
    values: getValuesForQuestion(
      question,
      getAnswer(gs),
      answers,
      granteeSurveys
    ),
    attributes: {
      series: gs.batch,
      grant_name: nullifyDummy(gs.grant_name),
      grantee_name: nullifyDummy(gs.grantee_name),
    },
    owner: {
      respondent_name:
        getAnswer(gs).respondent_name || gs.respondent_name || null,
      respondent_email:
        getAnswer(gs).respondent_email || gs.respondent_email || null,
    },
    source: {
      type: question.type,
      answer: Number(getAnswer(gs).id),
      grantee_survey: Number(gs.id),
      question: Number(question.id),
      survey: Number(gs.survey_id),
    },
    grantee_survey: Number(gs.id),
  }));

  if (
    question.type === SINGLE_MATRIX &&
    question.matrixQuestionType === SINGLE_CHOICE
  ) {
    return singleMatrixChoiceTabularData(question, datapoint);
  }

  const header = [
    'Responses',
    ...mergeMultiSeries(datapoint[0], [datapoint]).map((value) =>
      [datapoint].length > 1
        ? [value.label, value.subLabel].join('\n')
        : value.subLabel || value.label
    ),
    ...Object.keys(datapoint[0].attributes).map((key) =>
      capitalize(key.replace('_', ' '))
    ),
  ];

  const body = datapoint.map((dp) => [
    dp.label,
    ...mergeMultiSeries(dp, [datapoint]).map((v) => v.value),
    ...Object.keys(datapoint[0].attributes).map(
      (key) => dp.attributes[key] || NO_ANSWER
    ),
  ]);

  return [header].concat(body);
};

export const getResponsesForQuestionOptions = (
  question: any,
  options: string[] = [],
  answers: any = []
) =>
  answers
    .filter((a) => {
      const { simple_value, other_value, object_value } = a?.response ?? {};
      const hasTypedOption = options.some((o) =>
        Boolean(o === trimValue(simple_value))
      );
      const hasAnsweredSimple = Boolean(hasValue(simple_value));
      const hasAnsweredOther = Boolean(hasValue(other_value));
      const isBlank = !hasAnsweredSimple && !hasAnsweredOther;
      const hasAnsweredMulti =
        options.length > 0
          ? options.some((option) => (object_value || []).includes(option))
          : Boolean(
              Array.isArray(object_value) && object_value.some((i) => i != null)
            );

      if (question.type === SINGLE_MATRIX) return true;
      if (question.type === MULTIPLE_CHOICE) return hasAnsweredMulti;
      if (hasTypedOption) return true;
      if (options.includes(ANSWERED) && hasAnsweredSimple) return true;
      if (options.includes(HAS_VALUE) && hasAnsweredSimple) return true;
      if (options.includes(OTHER) && hasAnsweredOther) return true;
      if (options.includes(NO_ANSWER) && isBlank) return true;
      if (options.includes(EMPTY) && isBlank) return true;
    })
    .map((a) => a.grantee_survey.id);

export const groupBySeries = (question, tabular, compare, compareQuestion) => {
  const indexes = {};
  const payload = [[null]];
  const isNumericCompare =
    compareQuestion && compare && isNumericalQuestion(question);

  innerHead(isNumericCompare ? compare : tabular).forEach((header) => {
    payload[0] ??= [];
    payload[0].push(header);
  });

  const uniqueSeries = tabular
    .slice(1)
    .map((row) => row[row.length - 3])
    .filter((el, i, arr) => arr.indexOf(el) === i);
  uniqueSeries.forEach((serie, s) => (indexes[serie] = s + 1));
  uniqueSeries.forEach((serie) =>
    payload.push([serie, ...payload[0].slice(1).fill(0)])
  );

  tabular.forEach((row, i) => {
    if (i == 0) return; // response header

    innerData(row).forEach((value, j) => {
      const series = row[row.length - 3];
      const matrix = payload[indexes[series]] || [];

      if (innerHead(compare).length === 1)
        return (matrix[j + 1] += parseFloat(compare[i][1]));
      if (isNumericCompare)
        return innerData(compare[i]).forEach((count, k) => {
          if (count !== 0) matrix[k + 1] += value;
        });
      return (matrix[j + 1] += parseFloat(value));
    });
  });

  return payload;
};

export const matrixCompareData = (tabularData, compareData) => {
  if (!compareData?.[0]) return tabularData.map((row) => row.slice(0, -3));

  const serieLabel = compareData[0]?.[0] || '';
  const payload = [
    [serieLabel].concat(innerHead(tabularData).map((cell) => cell)),
    ...innerHead(compareData).map((cell) => [cell]),
  ];

  const serieSize = innerHead(tabularData).length;
  const crossSize = innerHead(compareData).length;
  const cross = tabularData.map((row, i) => [
    ...row.slice(0, row.length - 3),
    ...innerData(compareData[i]),
    ...row.slice(-3),
  ]);

  cross.forEach(
    (row) =>
      row.slice(1, -3 - crossSize)?.forEach(
        (x, i) =>
          row.slice(1 + serieSize, -3)?.forEach((y, j) => {
            payload[j + 1] ??= 0;
            payload[j + 1][i + 1] ??= 0;
            if (y > 0) payload[j + 1][i + 1] += typeof x === 'number' ? x : 0;
          })
      )
  );

  return payload;
};

export const resolveMatcher = (val, filter) => {
  if (filter.type === 'date') {
    switch (filter.matcher) {
      case 'lt':
        return new Date(val) < new Date(filter.result);
      case 'gt':
        return new Date(val) > new Date(filter.result);
      default:
        return false;
    }
  } else {
    switch (filter.matcher) {
      case 'not_eq':
        return val !== filter.result;
      case 'eq':
        return val === filter.result;
      case 'cont':
        return val.match(new RegExp(filter.result, 'i'));
      case 'not_cont':
        return !val.match(new RegExp(filter.result, 'i'));
      default:
        return false;
    }
  }
};

export const areAllAnswersUnique = (filteredRows) => {
  const distinct = filteredRows.slice(1).flatMap(([_, ...values]) => values);
  if (distinct.length === 0) return true;
  return distinct.every((answer) => [0, 1].includes(answer)); //check if all of them has 0 or 1 response at maximum
};

export const chartDataFactory = (unfilteredRows, state, question) => {
  const semiFilteredRows = unfilteredRows
    .filter((_, i) =>
      state.ignoredRows.length > 0
        ? state.ignoredRows.includes(i) === false
        : true
    )
    .map((row) =>
      row.filter((_, i) =>
        state.ignoredColumns.length > 0
          ? state.ignoredColumns.includes(i) === false
          : true
      )
    );

  const filteredCategoriesIndexes = semiFilteredRows.map(([_a, ...b], i) =>
    Number(b) > 0 || b.some((x) => x > 0) ? i : null
  );

  const [header, ...rows] = semiFilteredRows
    .filter((_, i) =>
      state.settings?.hideEmpty
        ? i === 0 || filteredCategoriesIndexes.includes(i)
        : true
    )
    .filter((row) =>
      state.settings.hideNoAnswer ? row?.[0] != NO_ANSWER : true
    );

  const filteredRows = [header].concat(
    rows.sort((a, b) => {
      if (b?.[0] == 'No Answer') return -1;
      return 0;
    })
  );
  const categories =
    filteredRows
      ?.filter((row) => {
        if (!Array.isArray(row)) return false;
        const [_a, b] = row;
        return b >= 0;
      })
      ?.map(([label]) => String(label)) ?? [];
  return {
    unfilteredRows,
    filteredRows,
    categories,
    settings: state.settings,
    chartType: state.chartType,
    question,
  };
};

export const chartDataTagFactory = (data, state, question) => {
  let categories;
  let rowSeries: string[] = [];
  let rowValues: string[][] = [];

  const uniqueLabels = uniq(data.rows.map((row) => row?.[0] || NO_ANSWER));
  const uniqueValues = uniq(data.rows.map((row) => row[1]));

  if (state.settings.groupBySeries) {
    categories = uniqueValues.slice();
    rowSeries = ['Responses', ...uniqueLabels];
    rowValues = uniqueValues.map((serie) => [
      serie,
      ...uniqueLabels.map(
        (tag) =>
          data.rows.find((dr) => dr[0] === tag && dr[1] === serie)?.[2] ?? 0
      ),
    ]);
  } else {
    categories = uniqueLabels.slice();
    rowSeries = ['Responses', 'Tag Count'];
    rowValues = data.rows.slice();
  }
  const unfilteredRows = [rowSeries, ...rowValues];

  const filteredRows = unfilteredRows
    .filter((_, i) =>
      state.ignoredRows.length > 0
        ? state.ignoredRows.includes(i) === false
        : true
    )
    .map((row) =>
      row.filter((_, i) =>
        state.ignoredColumns.length > 0
          ? state.ignoredColumns.includes(i) === false
          : true
      )
    );

  return {
    unfilteredRows,
    filteredRows,
    categories,
    settings: state.settings,
    chartType: state.chartType,
    question,
  };
};

const xAxisChartType = (filteredRows, questionType) => {
  // linear, logarithmic, datetime or category
  const values = filteredRows?.slice(1);
  if (values.length == 0) return 'category';
  if (values.every(([serie]) => isNumeric(serie))) return 'linear';
  return 'category';
};

export const getAxisId = (valueType) => {
  if (valueType === 'reach_tag_avg') return 'reach';
  if (valueType === 'reach_tag_total') return 'reach';
  if (valueType === 'funding_total_percentage') return 'percentage';
  if (valueType === 'grantees_percentage') return 'percentage';
  if (valueType === 'grants_percentage') return 'percentage';
  if (valueType === 'grantees_in_tag_group_percentage') return 'percentage';
  if (valueType === 'grants_in_tag_group_percentage') return 'percentage';
  if (valueType === 'funding_total') return 'money';
  if (String(valueType).endsWith('_percentage')) return 'percentage';
  // if (valueType === "grantees_total") return "count";
  // if (valueType === "grants_total") return "count";
  // if (valueType === "taggings_count") return "count";
  // if (valueType === "funding_count") return "count";
  // if (valueType === "cross_tab") return "count";
  // if (valueType === "grantees_in_tag_group") return "count";
  // if (valueType === "grants_in_tag_group") return "count";
  return undefined;
};
const sumTotal = (arr) => (arr ?? []).reduce((n, acc) => n + acc, 0);
const sortSurvey = (a, b) => {
  const aa = sumTotal([].concat(a).filter((i) => isNumeric(i)));
  const bb = sumTotal([].concat(b).filter((i) => isNumeric(i)));
  return bb - aa;
};
const transpose = (arr) =>
  arr.reduce((r, a) => a.map((v, i) => [...(r[i] || []), v]), []);

export const getSurveyConfiguration = ({
  categories,
  filteredRows = [],
  settings,
  chartType,
  question,
}) => {
  const stacking =
    settings.aggregateColumns === false &&
    settings.groupBySeries === false &&
    [MULTIPLE_CHOICE, SINGLE_CHOICE, SHORT_TEXT].includes(question.type);
  let yLabel = 'Occurrences';
  if ([question.type, question.matrixQuestionType].includes(NUMERICAL))
    yLabel = 'Total';
  if ([question.type, question.matrixQuestionType].includes(FINANCIAL))
    yLabel = `Total in ${question.currency}`;

  const formatCompactNumber = function (number) {
    const formatter = new Intl.NumberFormat('en-US', {
      notation: 'compact',
      compactDisplay: 'short',
    });
    return formatter.format(number);
  };

  const formatValueFn = (val = 0) => {
    if (question.type === FINANCIAL) {
      const formattedValue = settings.useCompactNotation
        ? formatCompactNumber(val) + ` ${question.currency} `
        : formatMoney(val, `${question.currency} `);
      return formattedValue;
    }

    if (question.type === NUMERICAL) {
      const formattedValue = settings.useCompactNotation
        ? formatCompactNumber(val)
        : formatNumber(val);
      return formattedValue;
    }

    const formattedValue = settings.useCompactNotation
      ? formatCompactNumber(val)
      : val;
    return formattedValue;
  };
  const seriesFormatter = function () {
    return `<b>${formatValueFn(this.y)}</b>`;
  };
  const toolTipFormatter = function () {
    return [
      this.point.name || this.key,
      filteredRows[0]?.length > 2 && this.series.name,
      `${yLabel}: ${formatValueFn(this.y)}`,
    ]
      .filter((el) => el === 0 || !!el)
      .join('<br />');
  };
  const type = chartType.replace('responseList', 'column');
  const transposed = settings.groupBySeries
    ? transpose(filteredRows)
    : filteredRows.slice();
  const sortedRows = settings.dataSorting
    ? transposed
        .slice(0, 1)
        .concat(
          transposed
            .slice(1)
            [settings.xAxisReversed ? 'reverse' : 'slice']()
            .sort(sortSurvey)
        )
    : filteredRows.slice();
  const outputRows = settings.groupBySeries
    ? transpose(sortedRows)
    : sortedRows.slice();
  const sortedCategories = settings.dataSorting
    ? outputRows.slice(1).map((r) => r[0])
    : categories;

  return {
    title: { text: '', style: { display: 'none' } }, // to hide title/default title in exports
    chart: {
      type,
      displayErrors: false,
      spacing: [16, 24, 16, 24],
      allowMutatingData: false,
    },
    legend: {
      enabled: settings.hideLegend != true,
      verticalAlign: settings.legendOnTop ? 'top' : 'bottom',
    },
    tooltip: { toolTipFormatter },
    plotOptions: {
      pie: {
        dataLabels: {
          format: `<b>{point.name}</b><br />${
            settings.dataLabelShowPercent ? '{point.percentage:.0f}%<br/>' : ''
          }${settings.dataLabelShowAbsolute ? '{point.y}' : ''}`,
        },
      },
      series: {
        stacking,
        dataLabels: {
          enabled: settings.showDataLabels,
          filter: { property: 'y', operator: '>', value: 0 },
          formatter: seriesFormatter,
        },
      },
    },
    xAxis: {
      type: xAxisChartType(filteredRows, question.type),
      categories: settings.switchRowsAndColumns ? null : sortedCategories,
      allowOverlap: true,
      allowDecimals: false,
      title: { text: null },
    },
    yAxis: {
      allowDecimals: false,
      maxPadding: yLabel === 'Occurrences' ? 0 : 0.025,
      endOnTick: yLabel !== 'Occurrences',
      title: { text: yLabel },
    },
    data: {
      switchRowsAndColumns: settings.switchRowsAndColumns,
      rows: outputRows,
    },
  };
};

export const isArrayOfString = (inspected) =>
  inspected &&
  Array.isArray(inspected) &&
  inspected.every((i) => typeof i == 'string');

const answerValue = (question, answer) => {
  switch (question.type) {
    case COUNTRY:
    case MULTIPLE_CHOICE:
      return [
        ...new Set(
          Object.values(answer.response?.object_value || {})
            .concat(
              answer.response.other_value
                ? `Other: ${answer.response.other_value}`
                : null
            )
            .filter((value) => value != null)
        ),
      ].map((value) => `"${value}"`);

    case SINGLE_MATRIX:
      if (!question.settings.singleMatrixColumns) {
        return question.matrixValues.map((row) => [
          row.title,
          `"${answer.response?.object_value?.[row.title] || '-'}"`,
          '\n',
        ]);
      }

      const header = [
        '-',
        ...question.settings.columns.map((column) => `"${column.title}"`),
        '\n',
      ];
      const rowValues = question.matrixValues.map((row) => {
        return [
          `"${row.title}"`,
          ...(question.settings.columns || []).map((column) => {
            return question.matrixQuestionType === SINGLE_CHOICE
              ? answer.response.object_value?.[row.title] === column.title
                ? 'O'
                : '-'
              : `"${answer.response.object_value?.[
                  `${row.title}_${column.id}`
                ]}"` || '-';
          }),
          '\n',
        ];
      });

      return [header, ...rowValues];

    case DATE:
      return `"${new Date(answer.response.simple_value).toDateString()}"`;
    case SHORT_TEXT:
    case LONG_TEXT:
    case SINGLE_CHOICE:
    case RATING:
    case FINANCIAL:
    case NUMERICAL:
    case OPINION_SCALE:
      const totalSize =
        (answer.response?.simple_value || '').length +
        (answer.response?.other_value || '').length;
      if (totalSize == 0) return null;
      return `"${answer.response.simple_value}"`;
  }
};

export const exportAnswersTable = (question, answers, responses) => {
  return responses
    .map((response) => {
      const answer =
        answers.find((a) => a.grantee_survey.id == response.id) ?? {};

      return [
        `"${respondentLabelFromResponse(response)}"`,
        answerValue(question, answer),
      ];
    })
    .join('\n');
};
