import React from 'react';
import classNames from 'classnames/bind';
import { List } from 'material-ui/List';
import PropTypes from 'prop-types';
import CreateTag from 'src/components/CreateTag/CreateTag';
import { Popover, Tag, TextField, Tooltip } from 'src/components/IMUI';
import withAccessSupport from 'src/components/Survey/withAccessSupport';
import debounce from 'src/utils/debounce';
import { containsText } from 'src/utils/string';
import TagSelectorCategory from './TagSelectorCategory';
import TagSelectorTag from './TagSelectorTag';
import cls from './TagSelector.module.css';
import colors from 'src/css/constants.json';
import { Icon } from 'im/ui';
const cx = classNames.bind(cls);

@withAccessSupport
export default class TagSelector extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    readOnly: PropTypes.bool,
    noTagProps: PropTypes.object,
    allowCreate: PropTypes.bool,
    multiple: PropTypes.bool,
    alt: PropTypes.bool,
    openOnTagClick: PropTypes.bool,
    showTags: PropTypes.bool,
    selected: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.number,
      PropTypes.string,
    ]),
    onRemove: PropTypes.func,
    onEdit: PropTypes.func,
    onChange: PropTypes.func,
    filter: PropTypes.func,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    zIndex: PropTypes.number,
    popoverProps: PropTypes.object,
    popoverClassName: PropTypes.string,
    tagsWithTagGroups: PropTypes.array,
    tagCategories: PropTypes.array,
    handleGetTagCategoriesPublic: PropTypes.func,
    handleGetTagCategoriesPrivate: PropTypes.func,
    onPopoverOpen: PropTypes.func,
    onPopoverClose: PropTypes.func,
  };

  static defaultProps = {
    openOnTagClick: true,
    readOnly: false,
    noTagProps: { label: '', grey: false, square: true, outline: false },
    showTags: true,
    allowCreate: true,
    filter: () => true,
    selected: [],
    tagsWithTagGroups: [],
    zIndex: 1400, // to allow it to play nice with dialog default of 1500
  };

  getAllTreeTagsPerCategory = (category) =>
    (category.tags || []).concat(
      (category.nested_child_categories || []).flatMap(({ tags }) => tags)
    );

  constructor(props) {
    super(props);
    this.state = {
      popoverOpen: false,
      createTagOpen: false,
      searchText: '',
      selectedTags: this.getSelectedTags(
        props.selected,
        props.tagsWithTagGroups
      ),
      selectedTagCategories: this.getSelectedTagCategories(
        props.selected,
        props.tagCategories
      ),
    };
  }

  getSelectedTags(selected, _tagsWithTagGroups) {
    const currentSelectedTags = [] || this.state.selectedTags;
    const tagsWithTagGroups =
      _tagsWithTagGroups || this.props.tagsWithTagGroups;
    const tagIds = Array.isArray(selected) ? selected : [selected];

    return tagIds
      .map(
        (id) =>
          currentSelectedTags.find((t) => t.id === id) ||
          this.getTag(id, tagsWithTagGroups)
      )
      .filter((tag) => !!tag);
  }

  getSelectedTagCategories(selected, tagCategories) {
    const selectedArray = Array.isArray(selected) ? selected : [selected];

    return tagCategories.reduce((acc, category) => {
      const tagCategoryTags = this.getAllTreeTagsPerCategory(category);
      const categorySelected = tagCategoryTags.every((t) =>
        selectedArray.includes(t.id)
      );
      const accWithChildren =
        category.child_categories?.length > 0
          ? acc
          : [
              ...acc,
              ...this.getSelectedTagCategories(
                selected,
                category.child_categories
              ),
            ];
      return categorySelected
        ? [...accWithChildren, category.id]
        : accWithChildren;
    }, []);
  }

  getTag = (tagId, _tagsWithTagGroups) => {
    const tagsWithTagGroups =
      _tagsWithTagGroups || this.props.tagsWithTagGroups;
    return tagsWithTagGroups.find(
      (tag) => tag.id === tagId || (tag.tagGroup && tag.tagGroup.id === tagId)
    );
  };

  getLabel = () => {
    if (this.props.label === true)
      return this.props.multiple ? 'Tags:' : 'Tag:';
    return typeof this.props.label === 'string' ? this.props.label : '';
  };

  getUpdatedSelectedTags = (tag, selectedTags) => {
    if (!this.props.multiple) {
      return selectedTags.length > 0 && tag.id === selectedTags[0].id
        ? { ids: [], set: [] }
        : { ids: [tag.id], set: [tag] };
    }

    const tagIndex = selectedTags.findIndex((t) => t.id === tag.id);
    if (tagIndex === -1) {
      const newTags = selectedTags.concat([tag]);
      return { ids: newTags.map(({ id }) => id), set: newTags };
    }

    const newSelectedTags = selectedTags.filter(
      (t, index) => index !== tagIndex
    );
    return { ids: newSelectedTags.map(({ id }) => id), set: newSelectedTags };
  };

  handleAddClicked = (ev) => {
    ev?.preventDefault();
    if (this.props.readOnly) return;
    this.setState({ popoverOpen: true, searchText: '' }, Tooltip.rebuild);
    this.props.onPopoverOpen?.();
  };

  handlePopoverClose = () => {
    this.setState({ popoverOpen: false }, Tooltip.rebuild);
    this.props.onPopoverClose?.();
  };

  handleCreateClicked = () => {
    this.setState({ createTagOpen: true, popoverOpen: false });
  };

  handleRemoveTag = (tag) => {
    if (!this.props.onRemove || this.props.readOnly) return;
    const newSelectedTags = this.state.selectedTags.filter(
      (t) => t.id !== tag.id
    );
    this.setState({ selectedTags: newSelectedTags }, () =>
      this.props.onRemove(tag.id, tag, newSelectedTags)
    );
  };

  handleTagCreate = async (tags) => {
    this.props.handleGetTagCategoriesPublic?.();
    this.props.handleGetTagCategoriesPrivate?.();
    this.handleCreateClose();

    if (!tags || !Array.isArray(tags)) return;
    await this.handleTagToggled(tags, true);
  };

  handleCreateClose = () => {
    this.setState({ createTagOpen: false, popoverOpen: this.props.multiple });
  };

  handleTagToggled = (tags, keepOpen = false) => {
    const { selectedTags } = this.state;
    const { multiple, onChange } = this.props;
    const tagsArray = Array.isArray(tags) ? tags : [tags];

    const result = (!multiple ? tagsArray.slice(0, 1) : tagsArray).reduce(
      (acc, tag) => {
        const tagResult = this.getUpdatedSelectedTags(tag, selectedTags);
        return {
          ids: [...acc.ids, ...tagResult.ids],
          set: [...acc.set, ...tagResult.set],
        };
      },
      { ids: [], set: [] }
    );

    if (!keepOpen && !multiple) {
      this.handlePopoverClose();
    }
    return this.setState({ selectedTags: result.set }, () =>
      onChange?.(
        multiple ? result.ids : result.ids[0],
        multiple ? result.set : result.set[0]
      )
    );
  };

  handleTagCategory = (tagGroup) => {
    const { selectedTagCategories, selectedTags } = this.state;
    const { filter, onChange } = this.props;
    const categoryIndex = selectedTagCategories.indexOf(tagGroup.id);
    const active = categoryIndex > -1;
    let newSelectedTags = [...selectedTags];
    let newSelectedTagCategories = [...selectedTagCategories];

    const tagGroupTags = this.getAllTreeTagsPerCategory(tagGroup);

    if (active) {
      tagGroupTags.filter(filter).forEach((tag) => {
        newSelectedTags = newSelectedTags.filter((t) => t.id !== tag.id);
      });
      newSelectedTagCategories.splice(categoryIndex, 1);
    } else {
      tagGroupTags.filter(filter).forEach((tag) => {
        newSelectedTags = newSelectedTags.find((t) => t.id === tag.id)
          ? newSelectedTags
          : newSelectedTags.concat([tag]);
      });
      newSelectedTagCategories.push(tagGroup.id);
    }

    this.setState(
      {
        selectedTags: newSelectedTags,
        selectedTagCategories: newSelectedTagCategories,
      },
      () =>
        onChange?.(
          newSelectedTags.map(({ id }) => id),
          newSelectedTags
        )
    );
  };

  handleFilterChange = debounce((searchText) => {
    this.setState({ searchText }, Tooltip.rebuild);
  }, 300);

  filterCategoryTags = (categoryTags) =>
    (categoryTags || []).filter((t) => {
      if (!this.state.searchText?.length) return t.title;
      return (this.props.filter?.(t) || true) &&
        containsText(t.title, this.state.searchText)
        ? t.title
        : null;
    });

  renderTagCategories = (childCategories, treeLevel = 0) => {
    if (treeLevel === 0) {
      const getTagsArray = (cat) =>
        cat
          ?.flatMap((a) => this.filterCategoryTags(a.tags)?.length ?? 0)
          .concat(cat?.flatMap((a) => getTagsArray(a.child_categories ?? [])));

      const noEntries =
        treeLevel === 0 && getTagsArray(childCategories).every((a) => a === 0);
      if (noEntries && this.state.searchText?.length > 0)
        return (
          <div className={cx('tagSelector-popover-empty')}>
            No tags match your query
          </div>
        );
      if (noEntries) return <div className={cx('tagSelector-popover-empty')} />;
    }

    return childCategories.flatMap((category) => {
      const tagsFiltered =
        this.filterCategoryTags(category.tags).sort((a, b) =>
          a.title?.localeCompare(b.title)
        ) ?? [];
      const hasTree =
        tagsFiltered.length > 0 || category.child_categories?.length > 0;
      if (!hasTree) return null;

      return [
        tagsFiltered.length === 0 ? (
          []
        ) : (
          <TagSelectorCategory
            key={`${treeLevel}_category_${category.id}`}
            category={category}
            treeLevel={treeLevel}
            isSelectable={this.props.multiple}
            isSelected={this.state.selectedTagCategories.includes(category.id)}
            onClick={this.handleTagCategory}
          />
        ),
      ].concat(
        <div
          style={{ marginLeft: 8 * (treeLevel + 1) }}
          key={`${treeLevel + 1}_${category.id}`}
        >
          <div>
            {tagsFiltered.map((tag) => (
              <TagSelectorTag
                key={tag.id}
                tag={tag}
                tip={tag.description}
                isSelected={this.state.selectedTags.some(
                  ({ id }) => id == tag.id
                )}
                onClick={this.handleTagToggled}
              />
            ))}
          </div>
          {category.child_categories?.length > 0 && (
            <div>
              {this.renderTagCategories(
                category.child_categories,
                treeLevel + 1
              )}
            </div>
          )}
        </div>
      );
    });
  };

  renderTags() {
    const showTagFullName = (tag) => (tag.title || '').length > 30;
    const canAddAnotherTag =
      this.props.readOnly != true &&
      (this.props.multiple || this.state.selectedTags.length == 0);

    if (!this.props.showTags)
      return (
        <div className={cls.tagSelectorWrapper}>
          {this.getLabel() && (
            <span className={cx('tagSelector-label')}>{this.getLabel()}</span>
          )}
        </div>
      );

    return (
      <div className={cls.tagSelectorWrapper}>
        {this.getLabel() && (
          <span className={cx('tagSelector-label')}>{this.getLabel()}</span>
        )}

        {this.state.selectedTags.map((tag, index) => (
          <div
            className={`${cx('tagSelector-tag-div')} tagSelector-tag-div`}
            key={`${tag.id}_${index}`}
            data-tip={tag.description}
          >
            <Tag
              {...(this.props.alt ? { alt: true } : {})}
              square
              tip={showTagFullName(tag) ? tag.title : tag.tagGroup?.title}
              className={cx('tagSelector-tag')}
              label={tag.title}
              onRemove={() => this.handleRemoveTag(tag)}
              labelProps={{ className: cx('tagSelector-tag-label') }}
              onEdit={this.props.onEdit ? () => this.props.onEdit?.(tag) : null}
              onClick={
                this.props.openOnTagClick && !this.props.readOnly
                  ? this.handleAddClicked
                  : null
              }
            />
          </div>
        ))}

        {this.props.noTagProps.showEmptyLabel &&
          !this.state.selectedTags.length && (
            <span style={{ color: colors['mid-grey'], fontSize: 14 }}>
              No Tags
            </span>
          )}

        {canAddAnotherTag && (
          <Tag
            renderIconLeft={
              this.props.noTagProps.icon || (() => <Icon name="tag" />)
            }
            className={cx('tagSelector-add', this.props.noTagProps.className)}
            onClick={
              !this.props.readOnly
                ? this.props.noTagProps.onClick ?? this.handleAddClicked
                : null
            }
            grey={this.props.noTagProps.grey}
            square={this.props.noTagProps.square}
            outline={this.props.noTagProps.outline}
            label={
              this.props.noTagProps.label ||
              `Add tag${this.props.multiple ? 's' : ''}`
            }
          />
        )}
      </div>
    );
  }

  render() {
    return (
      <div className={cx('tagSelector', this.props.className)}>
        <Popover
          className={this.props.popoverClassName}
          dark
          customWidth={600}
          canAutoPosition={true}
          zIndex={this.props.zIndex}
          open={this.state.popoverOpen}
          onRequestClose={this.handlePopoverClose}
          header={
            <TextField
              noValidation
              hintText={`Find and select tag${this.props.multiple ? 's' : ''}`}
              value={this.state.searchText}
              onChange={this.handleFilterChange}
            />
          }
          footerLabel={this.props.allowCreate ? 'Create new' : null}
          footerAction={
            (this.props.allowCreate && this.handleCreateClicked) || null
          }
          {...this.props.popoverProps}
        >
          {this.props.tagCategories.length == 0 && (
            <div className={cx('tagSelector-popover-empty')}>
              No tags added yet. Create a tag and a tag group first
            </div>
          )}
          <List>{this.renderTagCategories(this.props.tagCategories, 0)}</List>
        </Popover>

        {this.renderTags()}

        {this.props.allowCreate && this.state.createTagOpen && (
          <CreateTag
            noSync
            open={this.state.createTagOpen}
            onRequestClose={this.handleCreateClose}
            onSuccessfulAdd={this.handleTagCreate}
            initialValues={
              !this.state.searchText
                ? undefined
                : { tagNames: [this.state.searchText] }
            }
          />
        )}
      </div>
    );
  }
}
